<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M4 8H6V10H4V8Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 12H4V14H6V12Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M8 8H10V10H8V8Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10 12H8V14H10V12Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M12 8V10H14V8H12Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M14 12H12V14H14V12Z" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.5 0C4.96599 0 5.34375 0.37776 5.34375 0.84375V2.25H12.6562V0.84375C12.6562 0.37776 13.034 0 13.5 0C13.966 0 14.3438 0.37776 14.3438 0.84375V2.25H14.9062C16.6149 2.25 18 3.63513 18 5.34375V14.9062C18 16.6149 16.6149 18 14.9062 18H3.09375C1.38513 18 0 16.6149 0 14.9062V5.34375C0 3.63512 1.38512 2.25 3.09375 2.25H3.65625V0.84375C3.65625 0.37776 4.03401 0 4.5 0ZM12.6562 3.9375H5.34375V4.78125C5.34375 5.24724 4.96599 5.625 4.5 5.625C4.03401 5.625 3.65625 5.24724 3.65625 4.78125V3.9375H3.09375C2.3171 3.9375 1.6875 4.5671 1.6875 5.34375V14.9062C1.6875 15.6829 2.31709 16.3125 3.09375 16.3125H14.9062C15.6829 16.3125 16.3125 15.6829 16.3125 14.9062V5.34375C16.3125 4.56709 15.6829 3.9375 14.9062 3.9375H14.3438V4.78125C14.3438 5.24724 13.966 5.625 13.5 5.625C13.034 5.625 12.6562 5.24724 12.6562 4.78125V3.9375Z"
		/>
	</svg>
</template>
