<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 5C4 4.44772 4.44772 4 5 4H13C13.5523 4 14 4.44772 14 5C14 5.55228 13.5523 6 13 6H5C4.44772 6 4 5.55228 4 5Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5 8C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10H13C13.5523 10 14 9.55228 14 9C14 8.44772 13.5523 8 13 8H5Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 18V3C0 1.34315 1.34315 0 3 0H15C16.6569 0 18 1.34315 18 3V11C18 12.6569 16.6569 14 15 14H5L0 18ZM4 12H15C15.5523 12 16 11.5523 16 11V3C16 2.44772 15.5523 2 15 2H3C2.44772 2 2 2.44772 2 3V13.592L4 12Z"
		/>
	</svg>
</template>
