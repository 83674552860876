<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.97821 0C7.02943 0 5.36087 1.18839 4.67089 2.87343L6.19882 4.38685C6.3097 2.96244 7.51172 1.84083 8.97821 1.84083C10.5178 1.84083 11.7659 3.07709 11.7659 4.60209V5.5225H7.34537L9.20386 7.36334H13.6244V11.7419L15.4829 13.5828V7.36334C15.4829 6.34667 14.6509 5.5225 13.6244 5.5225V4.60209C13.6244 2.06042 11.5443 0 8.97821 0Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.117 15.8237C15.117 15.8237 15.117 15.8237 15.117 15.8237L15.7278 16.4287C16.0907 16.7882 16.0907 17.371 15.7278 17.7304C15.3649 18.0899 14.7766 18.0899 14.4137 17.7304L13.2396 16.5675H4.33199C3.30557 16.5675 2.4735 15.7433 2.4735 14.7267V7.36334C2.4735 6.95223 2.60956 6.57259 2.83951 6.2662L1.27217 4.71375C0.909277 4.35431 0.909277 3.77153 1.27217 3.41208C1.63506 3.05264 2.22343 3.05264 2.58632 3.41208L15.117 15.8237ZM4.33199 7.7445V14.7267H11.3811L4.33199 7.7445Z"
		/>
	</svg>
</template>
