<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.72727 11.4545C5.72727 11.0027 6.09359 10.6364 6.54545 10.6364H11.4545C11.9064 10.6364 12.2727 11.0027 12.2727 11.4545C12.2727 11.9064 11.9064 12.2727 11.4545 12.2727H6.54545C6.09359 12.2727 5.72727 11.9064 5.72727 11.4545Z"
		/>
		<path
			d="M6.13636 4.90909C5.81087 4.90909 5.49871 5.03839 5.26855 5.26855C5.03839 5.49871 4.90909 5.81087 4.90909 6.13636C4.90909 6.46185 5.03839 6.77402 5.26855 7.00418C5.49871 7.23434 5.81087 7.36364 6.13636 7.36364C6.46185 7.36364 6.77402 7.23434 7.00418 7.00418C7.23434 6.77402 7.36364 6.46185 7.36364 6.13636C7.36364 5.81087 7.23434 5.49871 7.00418 5.26855C6.77402 5.03839 6.46185 4.90909 6.13636 4.90909Z"
		/>
		<path
			d="M11.8636 4.90909C11.5381 4.90909 11.226 5.03839 10.9958 5.26855C10.7657 5.49871 10.6364 5.81087 10.6364 6.13636C10.6364 6.46185 10.7657 6.77402 10.9958 7.00418C11.226 7.23434 11.5381 7.36364 11.8636 7.36364C12.1891 7.36364 12.5013 7.23434 12.7314 7.00418C12.9616 6.77402 13.0909 6.46185 13.0909 6.13636C13.0909 5.81087 12.9616 5.49871 12.7314 5.26855C12.5013 5.03839 12.1891 4.90909 11.8636 4.90909Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 9C0 4.02931 4.02931 0 9 0C13.9707 0 18 4.02931 18 9C18 13.9707 13.9707 18 9 18C4.02931 18 0 13.9707 0 9ZM9 1.63636C4.93305 1.63636 1.63636 4.93305 1.63636 9C1.63636 13.0669 4.93305 16.3636 9 16.3636C13.0669 16.3636 16.3636 13.0669 16.3636 9C16.3636 4.93305 13.0669 1.63636 9 1.63636Z"
		/>
	</svg>
</template>
