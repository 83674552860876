<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.99989 4.42859C8.6843 4.42859 8.42847 4.68443 8.42847 5.00002V8.42859H4.9999C4.6843 8.42859 4.42847 8.68443 4.42847 9.00002C4.42847 9.31561 4.6843 9.57145 4.9999 9.57145H8.42847V13C8.42847 13.3156 8.6843 13.5714 8.99989 13.5714C9.31549 13.5714 9.57132 13.3156 9.57132 13V9.57145H12.9999C13.3155 9.57145 13.5713 9.31561 13.5713 9.00002C13.5713 8.68443 13.3155 8.42859 12.9999 8.42859L9.57132 8.42859V5.00002C9.57132 4.68443 9.31549 4.42859 8.99989 4.42859Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM15.8571 9C15.8571 5.2129 12.7871 2.14286 9 2.14286C5.2129 2.14286 2.14286 5.2129 2.14286 9C2.14286 12.7871 5.2129 15.8571 9 15.8571C12.7871 15.8571 15.8571 12.7871 15.8571 9Z"
		/>
	</svg>
</template>
