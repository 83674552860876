<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.2455 0.631633C14.4034 -0.210535 13.038 -0.210544 12.1959 0.631605L1.44934 11.3781C1.31784 11.5096 1.22562 11.6752 1.18303 11.8562L0.0266027 16.771C-0.0527175 17.1081 0.04803 17.4622 0.292913 17.7071C0.537796 17.952 0.891948 18.0527 1.22906 17.9734L6.14385 16.817C6.32487 16.7744 6.49043 16.6822 6.62192 16.5507L17.3684 5.80419C18.2105 4.96206 18.2105 3.5967 17.3685 2.75457L15.2455 0.631633ZM13.8312 2.04579L15.9542 4.16873L16.0859 4.30859L5.40886 14.9353L2.34342 15.6566L3.0647 12.5912L13.7458 1.9956L13.8312 2.04579L13.7871 2.01217C13.8063 2.02449 13.8195 2.03401 13.8312 2.04579Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.1423 16C9.59005 16 9.14233 16.4477 9.14233 17C9.14233 17.5523 9.59005 18 10.1423 18H16.9995C17.5518 18 17.9995 17.5523 17.9995 17C17.9995 16.4477 17.5518 16 16.9995 16H10.1423Z"
		/>
	</svg>
</template>
