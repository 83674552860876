<script setup lang="ts">
import { PropType } from 'vue'
import { SpSvg } from '~/autogen/SpSvg'
import { getIconSizeConfig } from '~/config/iconSizesConfig'
import { IconSizes } from '~/constants/iconSizes'
import { SpSvgMap } from '~/autogen/SpSvgDict'
import { unfillableIcons } from '~/config/UnfillableIcons.js'
import { EmitsEnum } from '~~/src/constants/emits'

const props = defineProps({
    iconName: { type: String as PropType<SpSvg>, required: true },
    fill: String,
    iconSize: { type: String as PropType<IconSizes>, default: IconSizes.M },
})

const emit = defineEmits([EmitsEnum.Click])
const onClick = () => emit(EmitsEnum.Click)

const { iconName, iconSize, fill: _fill } = toRefs(props)
const size = computed(() => getIconSizeConfig(iconSize.value! as IconSizes))
const asset = computed(() => SpSvgMap.get(iconName!.value!))

const fill = computed(() => {
    return unfillableIcons.has(iconName.value) ? 'none' : _fill?.value
})
</script>

<template>
    <component :is="asset" :class="`${fill} ${size}`" :fill="fill == 'none' ? fill : ''" @click="onClick" />
</template>
