<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 5C8 4.44772 8.44771 4 9 4C9.55228 4 10 4.44772 10 5C10 5.55229 9.55228 6 9 6C8.44771 6 8 5.55229 8 5Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10 14L9 14L8 14C7.44772 14 7 13.5523 7 13C7 12.4477 7.44772 12 8 12V9C7.44772 9 7 8.55228 7 8C7 7.44771 7.44772 7 8 7L9 7C9.03452 7 9.06863 7.00175 9.10224 7.00516C9.6065 7.05637 10 7.48223 10 8M10 8.00197L10 12C10.5523 12 11 12.4477 11 13C11 13.5523 10.5523 14 10 14"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5564 4.44365 17.25 9 17.25ZM15.6071 9C15.6071 12.649 12.649 15.6071 9 15.6071C5.35098 15.6071 2.39286 12.649 2.39286 9C2.39286 5.35098 5.35097 2.39286 9 2.39286C12.649 2.39286 15.6071 5.35098 15.6071 9Z"
		/>
	</svg>
</template>
