<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_22_786)">
			<mask id="mask0_22_786" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="18" height="13">
				<path
					d="M16.2857 3H1.71429C0.767511 3 0 3.76751 0 4.71429V14.1429C0 15.0897 0.767511 15.8571 1.71429 15.8571H16.2857C17.2325 15.8571 18 15.0897 18 14.1429V4.71429C18 3.76751 17.2325 3 16.2857 3Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_22_786)">
				<path
					d="M16.2857 3H1.71429C0.767511 3 0 3.76751 0 4.71429V14.1429C0 15.0897 0.767511 15.8571 1.71429 15.8571H16.2857C17.2325 15.8571 18 15.0897 18 14.1429V4.71429C18 3.76751 17.2325 3 16.2857 3Z"
					fill="#E00000"
				/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.5714H18V15.8571H0V11.5714Z" fill="#FFCF00" />
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0 3H18V7.28571H0V3Z" fill="black" />
				<path
					d="M16.2856 3.42859H1.71418C1.0041 3.42859 0.428467 4.00422 0.428467 4.7143V14.1429C0.428467 14.8529 1.0041 15.4286 1.71418 15.4286H16.2856C16.9957 15.4286 17.5713 14.8529 17.5713 14.1429V4.7143C17.5713 4.00422 16.9957 3.42859 16.2856 3.42859Z"
					stroke="black"
					stroke-opacity="0.1"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_22_786">
				<rect width="18" height="12.8571" fill="white" transform="translate(0 3)" />
			</clipPath>
		</defs>
	</svg>
</template>
