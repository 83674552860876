<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_22_785)">
			<mask id="mask0_22_785" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="18" height="13">
				<path
					d="M16.2857 3H1.71429C0.767512 3 0 3.76751 0 4.71429V14.1429C0 15.0896 0.767512 15.8571 1.71429 15.8571H16.2857C17.2325 15.8571 18 15.0896 18 14.1429V4.71429C18 3.76751 17.2325 3 16.2857 3Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_22_785)">
				<path
					d="M16.2857 3H1.71429C0.767512 3 0 3.76751 0 4.71429V14.1429C0 15.0896 0.767512 15.8571 1.71429 15.8571H16.2857C17.2325 15.8571 18 15.0896 18 14.1429V4.71429C18 3.76751 17.2325 3 16.2857 3Z"
					fill="#22438B"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M2.57141 3.85712L0.834839 3.8794L0.857125 5.5714L15.4131 15.024L17.1591 14.9923L17.1266 13.3105L2.57141 3.85712Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M1.71432 3.85712L0.857178 4.71426L16.2857 15L17.1429 14.1428L1.71432 3.85712Z"
					fill="#C7152A"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M15.4286 3.85712H17.1429V5.5714C17.1429 5.5714 7.07145 11.9108 2.58688 15.024C2.53288 15.0617 0.874312 15.0265 0.874312 15.0265L0.741455 13.3971L15.4286 3.85712Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M16.3226 3.83313L17.1429 4.71427L1.71432 15L0.857178 14.1428L16.3226 3.83313Z"
					fill="#C7152A"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M6.85718 3.85712H11.1429V7.28569H17.1429V11.5714H11.1429V15H6.85718V11.5714H0.857178V7.28569H6.85718V3.85712Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M7.71432 3.85712H10.2857V8.14283H17.1429V10.7143H10.2857V15H7.71432V10.7143H0.857178V8.14283H7.71432V3.85712Z"
					fill="#C7152A"
				/>
				<path
					d="M16.2857 3.42859H1.7143C1.00422 3.42859 0.428589 4.00422 0.428589 4.7143V14.1429C0.428589 14.853 1.00422 15.4286 1.7143 15.4286H16.2857C16.9958 15.4286 17.5714 14.853 17.5714 14.1429V4.7143C17.5714 4.00422 16.9958 3.42859 16.2857 3.42859Z"
					stroke="black"
					stroke-opacity="0.1"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_22_785">
				<rect width="18" height="12.8571" fill="white" transform="translate(0 3)" />
			</clipPath>
		</defs>
	</svg>
</template>
