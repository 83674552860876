<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.82732 10.391C5.18724 10.121 5.69719 10.1925 5.96922 10.5499L5.9715 10.5528C5.97556 10.5578 5.98405 10.5683 5.99697 10.5834C6.02285 10.6135 6.06619 10.6619 6.12689 10.7226C6.24868 10.8444 6.43758 11.0129 6.69253 11.1829C7.19929 11.5208 7.96448 11.8637 9.00005 11.8637C10.0356 11.8637 10.8008 11.5208 11.3076 11.1829C11.5625 11.0129 11.7514 10.8444 11.8732 10.7226C11.9339 10.6619 11.9772 10.6135 12.0031 10.5834C12.016 10.5683 12.0245 10.5578 12.0286 10.5528L12.0309 10.5499C12.3029 10.1925 12.8129 10.121 13.1728 10.391C13.5343 10.6621 13.6075 11.1749 13.3364 11.5364L12.6819 11.0455C13.3364 11.5364 13.3367 11.5361 13.3364 11.5364L13.3354 11.5377L13.3343 11.5392L13.3318 11.5425L13.3252 11.5511C13.3202 11.5575 13.3139 11.5655 13.3064 11.575C13.2913 11.5939 13.271 11.6186 13.2455 11.6483C13.1947 11.7076 13.123 11.787 13.0303 11.8797C12.8453 12.0647 12.5739 12.3053 12.2153 12.5445C11.4947 13.0248 10.419 13.5 9.00005 13.5C7.58107 13.5 6.50536 13.0248 5.78484 12.5445C5.42616 12.3053 5.15483 12.0647 4.9698 11.8797C4.87709 11.787 4.80537 11.7076 4.75455 11.6483C4.72911 11.6186 4.70884 11.5939 4.69372 11.575C4.68616 11.5655 4.67987 11.5575 4.67487 11.5511L4.66832 11.5425L4.66576 11.5392L4.66466 11.5377L4.66416 11.537C4.66392 11.5367 4.66368 11.5364 5.31823 11.0455L4.66416 11.537C4.39304 11.1755 4.46583 10.6621 4.82732 10.391Z"
		/>
		<path
			d="M6.13636 4.90909C5.81087 4.90909 5.49871 5.03839 5.26855 5.26855C5.03839 5.49871 4.90909 5.81087 4.90909 6.13636C4.90909 6.46185 5.03839 6.77402 5.26855 7.00418C5.49871 7.23434 5.81087 7.36364 6.13636 7.36364C6.46185 7.36364 6.77402 7.23434 7.00418 7.00418C7.23434 6.77402 7.36364 6.46185 7.36364 6.13636C7.36364 5.81087 7.23434 5.49871 7.00418 5.26855C6.77402 5.03839 6.46185 4.90909 6.13636 4.90909Z"
		/>
		<path
			d="M11.8636 4.90909C11.5381 4.90909 11.226 5.03839 10.9958 5.26855C10.7657 5.49871 10.6364 5.81087 10.6364 6.13636C10.6364 6.46185 10.7657 6.77402 10.9958 7.00418C11.226 7.23434 11.5381 7.36364 11.8636 7.36364C12.1891 7.36364 12.5013 7.23434 12.7314 7.00418C12.9616 6.77402 13.0909 6.46185 13.0909 6.13636C13.0909 5.81087 12.9616 5.49871 12.7314 5.26855C12.5013 5.03839 12.1891 4.90909 11.8636 4.90909Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 0C4.02931 0 0 4.02931 0 9C0 13.9707 4.02931 18 9 18C13.9707 18 18 13.9707 18 9C18 4.02931 13.9707 0 9 0ZM1.63636 9C1.63636 4.93305 4.93305 1.63636 9 1.63636C13.0669 1.63636 16.3636 4.93305 16.3636 9C16.3636 13.0669 13.0669 16.3636 9 16.3636C4.93305 16.3636 1.63636 13.0669 1.63636 9Z"
		/>
	</svg>
</template>
