<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 4C9.55228 4 10 4.44772 10 5V10C10 10.5523 9.55228 11 9 11C8.44771 11 8 10.5523 8 10V5C8 4.44772 8.44772 4 9 4Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12C8.44771 12 8 12.4477 8 13C8 13.5523 8.44771 14 9 14Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5564 4.44365 17.25 9 17.25C13.5564 17.25 17.25 13.5564 17.25 9C17.25 4.44365 13.5564 0.75 9 0.75ZM2.39286 9C2.39286 5.35098 5.35098 2.39286 9 2.39286C12.649 2.39286 15.6071 5.35098 15.6071 9C15.6071 12.649 12.649 15.6071 9 15.6071C5.35098 15.6071 2.39286 12.649 2.39286 9Z"
		/>
	</svg>
</template>
