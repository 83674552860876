<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.13636 4.90909C5.81087 4.90909 5.49871 5.03839 5.26855 5.26855C5.03839 5.49871 4.90909 5.81087 4.90909 6.13636C4.90909 6.46185 5.03839 6.77402 5.26855 7.00418C5.49871 7.23434 5.81087 7.36364 6.13636 7.36364C6.46185 7.36364 6.77402 7.23434 7.00418 7.00418C7.23434 6.77402 7.36364 6.46185 7.36364 6.13636C7.36364 5.81087 7.23434 5.49871 7.00418 5.26855C6.77402 5.03839 6.46185 4.90909 6.13636 4.90909Z"
		/>
		<path
			d="M11.8636 4.90909C11.5381 4.90909 11.226 5.03839 10.9958 5.26855C10.7657 5.49871 10.6364 5.81087 10.6364 6.13636C10.6364 6.46185 10.7657 6.77402 10.9958 7.00418C11.226 7.23434 11.5381 7.36364 11.8636 7.36364C12.1891 7.36364 12.5013 7.23434 12.7314 7.00418C12.9616 6.77402 13.0909 6.46185 13.0909 6.13636C13.0909 5.81087 12.9616 5.49871 12.7314 5.26855C12.5013 5.03839 12.1891 4.90909 11.8636 4.90909Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.82732 12.5182C5.18724 12.7881 5.69719 12.7167 5.96922 12.3592L5.9715 12.3564C5.97181 12.356 5.97214 12.3556 5.97249 12.3551L12.0309 12.3593C12.3029 12.7167 12.8129 12.7881 13.1728 12.5182C13.5343 12.2471 13.6075 11.7342 13.3364 11.3727L12.693 11.8553C13.3364 11.3727 13.3367 11.373 13.3364 11.3727L13.3354 11.3714L13.3343 11.37L13.3318 11.3666L13.3252 11.3581C13.3202 11.3516 13.3139 11.3436 13.3064 11.3341C13.2913 11.3152 13.271 11.2905 13.2455 11.2609C13.1947 11.2016 13.123 11.1221 13.0303 11.0294C12.8453 10.8444 12.5739 10.6038 12.2153 10.3647C11.4947 9.88434 10.419 9.40909 9.00005 9.40909C7.58107 9.40909 6.50536 9.88434 5.78484 10.3647C5.42616 10.6038 5.15483 10.8444 4.9698 11.0294C4.87709 11.1221 4.80537 11.2016 4.75455 11.2609C4.72911 11.2905 4.70884 11.3152 4.69372 11.3341C4.68616 11.3436 4.67987 11.3516 4.67487 11.3581L4.66832 11.3666L4.66576 11.37L4.66466 11.3714L4.66416 11.3721C4.66392 11.3724 4.66368 11.3727 5.31823 11.8636L4.66416 11.3721C4.39304 11.7336 4.46583 12.2471 4.82732 12.5182ZM12.029 12.3567L12.0302 12.3583L12.0309 12.3593L12.029 12.3567ZM12.0281 12.3557L5.97249 12.3551C5.97686 12.3497 5.98502 12.3397 5.99697 12.3258C6.02285 12.2956 6.06619 12.2472 6.12689 12.1865C6.24868 12.0647 6.43758 11.8962 6.69253 11.7262C7.19929 11.3884 7.96448 11.0455 9.00005 11.0455C10.0356 11.0455 10.8008 11.3884 11.3076 11.7262C11.5625 11.8962 11.7514 12.0647 11.8732 12.1865C11.9339 12.2472 11.9772 12.2956 12.0031 12.3258C12.0155 12.3402 12.0238 12.3504 12.0281 12.3557Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 9C0 4.02931 4.02931 0 9 0C13.9707 0 18 4.02931 18 9C18 13.9707 13.9707 18 9 18C4.02931 18 0 13.9707 0 9ZM9 1.63636C4.93305 1.63636 1.63636 4.93305 1.63636 9C1.63636 13.0669 4.93305 16.3636 9 16.3636C13.0669 16.3636 16.3636 13.0669 16.3636 9C16.3636 4.93305 13.0669 1.63636 9 1.63636Z"
		/>
	</svg>
</template>
