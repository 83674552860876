<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.7071 6.70711C13.0976 6.31658 13.0976 5.68342 12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289L5.29289 11.2929C4.90237 11.6834 4.90237 12.3166 5.29289 12.7071C5.68342 13.0976 6.31658 13.0976 6.70711 12.7071L12.7071 6.70711Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5564 0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9ZM9 15.6071C12.649 15.6071 15.6071 12.649 15.6071 9C15.6071 5.35098 12.649 2.39286 9 2.39286C5.35097 2.39286 2.39286 5.35098 2.39286 9C2.39286 12.649 5.35098 15.6071 9 15.6071Z"
		/>
	</svg>
</template>
