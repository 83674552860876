<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_22_784)">
			<mask id="mask0_22_784" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="18" height="13">
				<path
					d="M16.2857 3H1.71429C0.767511 3 0 3.76751 0 4.71429V14.1429C0 15.0897 0.767511 15.8571 1.71429 15.8571H16.2857C17.2325 15.8571 18 15.0897 18 14.1429V4.71429C18 3.76751 17.2325 3 16.2857 3Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_22_784)">
				<path
					d="M16.2857 3H1.71429C0.767511 3 0 3.76751 0 4.71429V14.1429C0 15.0897 0.767511 15.8571 1.71429 15.8571H16.2857C17.2325 15.8571 18 15.0897 18 14.1429V4.71429C18 3.76751 17.2325 3 16.2857 3Z"
					fill="white"
				/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0 3H6V15.8571H0V3Z" fill="#009342" />
				<path fill-rule="evenodd" clip-rule="evenodd" d="M12 3H18V15.8571H12V3Z" fill="#D02832" />
				<path
					d="M16.2857 3.42859H1.7143C1.00422 3.42859 0.428589 4.00422 0.428589 4.7143V14.1429C0.428589 14.8529 1.00422 15.4286 1.7143 15.4286H16.2857C16.9958 15.4286 17.5714 14.8529 17.5714 14.1429V4.7143C17.5714 4.00422 16.9958 3.42859 16.2857 3.42859Z"
					stroke="black"
					stroke-opacity="0.1"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_22_784">
				<rect width="18" height="12.8571" fill="white" transform="translate(0 3)" />
			</clipPath>
		</defs>
	</svg>
</template>
