//@ts-nocheck
import { SpSvg } from '~/autogen/SpSvg'
import IconBasicAlertCircle from '~/components/icon/Basic/AlertCircle'
import IconBasicArrowBottomSmall from '~/components/icon/Basic/ArrowBottomSmall'
import IconBasicArrowDown from '~/components/icon/Basic/ArrowDown'
import IconBasicArrowLeft from '~/components/icon/Basic/ArrowLeft'
import IconBasicArrowLeftSmall from '~/components/icon/Basic/ArrowLeftSmall'
import IconBasicArrowRight from '~/components/icon/Basic/ArrowRight'
import IconBasicArrowRightSmall from '~/components/icon/Basic/ArrowRightSmall'
import IconBasicArrowTop from '~/components/icon/Basic/ArrowTop'
import IconBasicArrowTopSmall from '~/components/icon/Basic/ArrowTopSmall'
import IconBasicBook from '~/components/icon/Basic/Book'
import IconBasicBulb from '~/components/icon/Basic/Bulb'
import IconBasicCalculator from '~/components/icon/Basic/Calculator'
import IconBasicCalendar from '~/components/icon/Basic/Calendar'
import IconBasicCarretDown from '~/components/icon/Basic/CarretDown'
import IconBasicCarretLeft from '~/components/icon/Basic/CarretLeft'
import IconBasicCarretRight from '~/components/icon/Basic/CarretRight'
import IconBasicCarretUp from '~/components/icon/Basic/CarretUp'
import IconBasicCart from '~/components/icon/Basic/Cart'
import IconBasicCheck from '~/components/icon/Basic/Check'
import IconBasicCheckCircle from '~/components/icon/Basic/CheckCircle'
import IconBasicComment from '~/components/icon/Basic/Comment'
import IconBasicCompetitors from '~/components/icon/Basic/Competitors'
import IconBasicCopy from '~/components/icon/Basic/Copy'
import IconBasicCreditCard from '~/components/icon/Basic/CreditCard'
import IconBasicDashboard from '~/components/icon/Basic/Dashboard'
import IconBasicDots from '~/components/icon/Basic/Dots'
import IconBasicDownload from '~/components/icon/Basic/Download'
import IconBasicEdit from '~/components/icon/Basic/Edit'
import IconBasicEmojiHappy from '~/components/icon/Basic/EmojiHappy'
import IconBasicEmojiQuite from '~/components/icon/Basic/EmojiQuite'
import IconBasicEmojiSad from '~/components/icon/Basic/EmojiSad'
import IconBasicEnter from '~/components/icon/Basic/Enter'
import IconBasicErrorCircle from '~/components/icon/Basic/ErrorCircle'
import IconBasicExit from '~/components/icon/Basic/Exit'
import IconBasicFile from '~/components/icon/Basic/File'
import IconBasicGraduationCap from '~/components/icon/Basic/GraduationCap'
import IconBasicHelp from '~/components/icon/Basic/Help'
import IconBasicHide from '~/components/icon/Basic/Hide'
import IconBasicHome from '~/components/icon/Basic/Home'
import IconBasicInfoCircle from '~/components/icon/Basic/InfoCircle'
import IconBasicLockClose from '~/components/icon/Basic/LockClose'
import IconBasicLockOpen from '~/components/icon/Basic/LockOpen'
import IconBasicMoney from '~/components/icon/Basic/Money'
import IconBasicPlus from '~/components/icon/Basic/Plus'
import IconBasicPlusCircleLight from '~/components/icon/Basic/PlusCircleLight'
import IconBasicSetting from '~/components/icon/Basic/Setting'
import IconBasicShow from '~/components/icon/Basic/Show'
import IconBasicSlider from '~/components/icon/Basic/Slider'
import IconBasicSparkle from '~/components/icon/Basic/Sparkle'
import IconBasicSubMenu from '~/components/icon/Basic/SubMenu'
import IconBasicTimes from '~/components/icon/Basic/Times'
import IconBasicTimesCircle from '~/components/icon/Basic/TimesCircle'
import IconBasicTimesCircleLight from '~/components/icon/Basic/TimesCircleLight'
import IconBasicTrash from '~/components/icon/Basic/Trash'
import IconBasicUndo from '~/components/icon/Basic/Undo'
import IconBasicUpload from '~/components/icon/Basic/Upload'
import IconCalendarCopy from '~/components/icon/Calendar/Copy'
import IconCalendarDollarSmall from '~/components/icon/Calendar/DollarSmall'
import IconCalendarEuroSmall from '~/components/icon/Calendar/EuroSmall'
import IconCalendarMinusSmall from '~/components/icon/Calendar/MinusSmall'
import IconCalendarModFreeze from '~/components/icon/Calendar/ModFreeze'
import IconCalendarModLink from '~/components/icon/Calendar/ModLink'
import IconCalendarModMarkup from '~/components/icon/Calendar/ModMarkup'
import IconCalendarModMax from '~/components/icon/Calendar/ModMax'
import IconCalendarModMin from '~/components/icon/Calendar/ModMin'
import IconCalendarModSemiLink from '~/components/icon/Calendar/ModSemiLink'
import IconCalendarPercentSmall from '~/components/icon/Calendar/PercentSmall'
import IconCalendarPlusSmall from '~/components/icon/Calendar/PlusSmall'
import IconCalendarUser from '~/components/icon/Calendar/User'
import IconCalendarUserAlt from '~/components/icon/Calendar/UserAlt'
import IconDashboardBarGraph from '~/components/icon/Dashboard/BarGraph'
import IconDashboardLineGraph from '~/components/icon/Dashboard/LineGraph'
import IconLangDe from '~/components/icon/Lang/De'
import IconLangEn from '~/components/icon/Lang/En'
import IconLangIt from '~/components/icon/Lang/It'

export const SpSvgMap = new Map<SpSvg, any>([
	[SpSvg.BasicAlertCircle, IconBasicAlertCircle],
	[SpSvg.BasicArrowBottomSmall, IconBasicArrowBottomSmall],
	[SpSvg.BasicArrowDown, IconBasicArrowDown],
	[SpSvg.BasicArrowLeftSmall, IconBasicArrowLeftSmall],
	[SpSvg.BasicArrowLeft, IconBasicArrowLeft],
	[SpSvg.BasicArrowRightSmall, IconBasicArrowRightSmall],
	[SpSvg.BasicArrowRight, IconBasicArrowRight],
	[SpSvg.BasicArrowTopSmall, IconBasicArrowTopSmall],
	[SpSvg.BasicArrowTop, IconBasicArrowTop],
	[SpSvg.BasicBook, IconBasicBook],
	[SpSvg.BasicBulb, IconBasicBulb],
	[SpSvg.BasicCalculator, IconBasicCalculator],
	[SpSvg.BasicCalendar, IconBasicCalendar],
	[SpSvg.BasicCarretDown, IconBasicCarretDown],
	[SpSvg.BasicCarretLeft, IconBasicCarretLeft],
	[SpSvg.BasicCarretRight, IconBasicCarretRight],
	[SpSvg.BasicCarretUp, IconBasicCarretUp],
	[SpSvg.BasicCart, IconBasicCart],
	[SpSvg.BasicCheckCircle, IconBasicCheckCircle],
	[SpSvg.BasicCheck, IconBasicCheck],
	[SpSvg.BasicComment, IconBasicComment],
	[SpSvg.BasicCompetitors, IconBasicCompetitors],
	[SpSvg.BasicCopy, IconBasicCopy],
	[SpSvg.BasicCreditCard, IconBasicCreditCard],
	[SpSvg.BasicDashboard, IconBasicDashboard],
	[SpSvg.BasicDots, IconBasicDots],
	[SpSvg.BasicDownload, IconBasicDownload],
	[SpSvg.BasicEdit, IconBasicEdit],
	[SpSvg.BasicEmojiHappy, IconBasicEmojiHappy],
	[SpSvg.BasicEmojiQuite, IconBasicEmojiQuite],
	[SpSvg.BasicEmojiSad, IconBasicEmojiSad],
	[SpSvg.BasicEnter, IconBasicEnter],
	[SpSvg.BasicErrorCircle, IconBasicErrorCircle],
	[SpSvg.BasicExit, IconBasicExit],
	[SpSvg.BasicFile, IconBasicFile],
	[SpSvg.BasicGraduationCap, IconBasicGraduationCap],
	[SpSvg.BasicHelp, IconBasicHelp],
	[SpSvg.BasicHide, IconBasicHide],
	[SpSvg.BasicHome, IconBasicHome],
	[SpSvg.BasicInfoCircle, IconBasicInfoCircle],
	[SpSvg.BasicLockClose, IconBasicLockClose],
	[SpSvg.BasicLockOpen, IconBasicLockOpen],
	[SpSvg.BasicMoney, IconBasicMoney],
	[SpSvg.BasicPlusCircleLight, IconBasicPlusCircleLight],
	[SpSvg.BasicPlus, IconBasicPlus],
	[SpSvg.BasicSetting, IconBasicSetting],
	[SpSvg.BasicShow, IconBasicShow],
	[SpSvg.BasicSlider, IconBasicSlider],
	[SpSvg.BasicSparkle, IconBasicSparkle],
	[SpSvg.BasicSubMenu, IconBasicSubMenu],
	[SpSvg.BasicTimesCircleLight, IconBasicTimesCircleLight],
	[SpSvg.BasicTimesCircle, IconBasicTimesCircle],
	[SpSvg.BasicTimes, IconBasicTimes],
	[SpSvg.BasicTrash, IconBasicTrash],
	[SpSvg.BasicUndo, IconBasicUndo],
	[SpSvg.BasicUpload, IconBasicUpload],
	[SpSvg.CalendarCopy, IconCalendarCopy],
	[SpSvg.CalendarDollarSmall, IconCalendarDollarSmall],
	[SpSvg.CalendarEuroSmall, IconCalendarEuroSmall],
	[SpSvg.CalendarMinusSmall, IconCalendarMinusSmall],
	[SpSvg.CalendarModFreeze, IconCalendarModFreeze],
	[SpSvg.CalendarModLink, IconCalendarModLink],
	[SpSvg.CalendarModMarkup, IconCalendarModMarkup],
	[SpSvg.CalendarModMax, IconCalendarModMax],
	[SpSvg.CalendarModMin, IconCalendarModMin],
	[SpSvg.CalendarModSemiLink, IconCalendarModSemiLink],
	[SpSvg.CalendarPercentSmall, IconCalendarPercentSmall],
	[SpSvg.CalendarPlusSmall, IconCalendarPlusSmall],
	[SpSvg.CalendarUserAlt, IconCalendarUserAlt],
	[SpSvg.CalendarUser, IconCalendarUser],
	[SpSvg.DashboardBarGraph, IconDashboardBarGraph],
	[SpSvg.DashboardLineGraph, IconDashboardLineGraph],
	[SpSvg.LangDe, IconLangDe],
	[SpSvg.LangEn, IconLangEn],
	[SpSvg.LangIt, IconLangIt],
])
