<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_18_758)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.40006 1.2C8.75561 0.933333 9.2445 0.933333 9.60006 1.2L17.6001 7.2C18.0419 7.53137 18.1314 8.15817 17.8001 8.6C17.4687 9.04183 16.8419 9.13137 16.4001 8.8L16 8.49996V16C16 16.5523 15.5523 17 15 17H3C2.44772 17 2 16.5523 2 16V8.50004L1.60006 8.8C1.15823 9.13137 0.53143 9.04183 0.200059 8.6C-0.131312 8.15817 -0.0417689 7.53137 0.400059 7.2L8.40006 1.2ZM10 15H14V6.99996L9.00006 3.25L4 7.00004V15H8V11C8 10.4477 8.44771 10 9 10C9.55229 10 10 10.4477 10 11V15Z"
			/>
		</g>
		<defs>
			<clipPath id="clip0_18_758">
				<rect width="18" height="18" />
			</clipPath>
		</defs>
	</svg>
</template>
