<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.2071 7.70711C13.5976 7.31658 13.5976 6.68342 13.2071 6.29289C12.8166 5.90237 12.1834 5.90237 11.7929 6.29289L7.5 10.5858L6.20711 9.29289C5.81658 8.90237 5.18342 8.90237 4.79289 9.29289C4.40237 9.68342 4.40237 10.3166 4.79289 10.7071L6.79289 12.7071C7.18342 13.0976 7.81658 13.0976 8.20711 12.7071L13.2071 7.70711Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5564 0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9ZM9 15.6071C12.649 15.6071 15.6071 12.649 15.6071 9C15.6071 5.35098 12.649 2.39286 9 2.39286C5.35097 2.39286 2.39286 5.35098 2.39286 9C2.39286 12.649 5.35098 15.6071 9 15.6071Z"
		/>
	</svg>
</template>
