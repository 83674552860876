<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.4517 6.69565C14.9683 8.06348 13.6638 9.04348 12.1304 9.04348C10.597 9.04348 9.29249 8.06348 8.80904 6.69565H1C0.447715 6.69565 0 6.24793 0 5.69565C0 5.14336 0.447715 4.69565 1 4.69565H8.70607C9.07779 3.1492 10.4699 2 12.1304 2C13.7909 2 15.183 3.1492 15.5547 4.69565H17C17.5522 4.69565 18 5.14336 18 5.69565C18 6.24793 17.5522 6.69565 17 6.69565H15.4517ZM13.6521 5.52174C13.6521 4.68131 12.9708 4 12.1304 4C11.2899 4 10.6086 4.68131 10.6086 5.52174C10.6086 6.36217 11.2899 7.04348 12.1304 7.04348C12.9708 7.04348 13.6521 6.36217 13.6521 5.52174Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.65211 16.8696C8.1855 16.8696 9.49 15.8896 9.97346 14.5217H16.9999C17.5522 14.5217 17.9999 14.074 17.9999 13.5217C17.9999 12.9694 17.5522 12.5217 16.9999 12.5217H10.0764C9.7047 10.9753 8.31261 9.82608 6.65211 9.82608C4.99161 9.82608 3.59952 10.9753 3.2278 12.5217H1C0.447715 12.5217 0 12.9694 0 13.5217C0 14.074 0.447715 14.5217 1 14.5217H3.33076C3.81422 15.8896 5.11872 16.8696 6.65211 16.8696ZM8.17385 13.3478C8.17385 14.1883 7.49254 14.8696 6.65211 14.8696C5.81168 14.8696 5.13037 14.1883 5.13037 13.3478C5.13037 12.5074 5.81168 11.8261 6.65211 11.8261C7.49254 11.8261 8.17385 12.5074 8.17385 13.3478Z"
		/>
	</svg>
</template>
