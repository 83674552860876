<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_18_749)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.99593 1.39948e-06L9.50433 7.43078e-07C10.6089 6.46513e-07 11.5043 0.895431 11.5043 2L11.5043 6L9.50433 6L9.50433 2L1.99593 2L1.99593 16L9.50433 16L9.50433 12L11.5043 12L11.5043 16C11.5043 17.1046 10.6089 18 9.50433 18L1.99593 18C0.891358 18 -0.00407036 17.1046 -0.00407046 16L-0.00407168 2C-0.00407178 0.895432 0.891357 1.49605e-06 1.99593 1.39948e-06Z"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12.8828 5.29289C13.2734 4.90237 13.9065 4.90237 14.297 5.29289L18.0041 9L14.297 12.7071C13.9065 13.0976 13.2734 13.0976 12.8828 12.7071C12.4923 12.3166 12.4923 11.6834 12.8828 11.2929L14.1757 10L5.58994 10C5.03765 10 4.58994 9.55228 4.58994 9C4.58994 8.44772 5.03765 8 5.58994 8L14.1757 8L12.8828 6.70711C12.4923 6.31658 12.4923 5.68342 12.8828 5.29289Z"
			/>
		</g>
		<defs>
			<clipPath id="clip0_18_749">
				<rect width="18" height="18" />
			</clipPath>
		</defs>
	</svg>
</template>
