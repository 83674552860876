<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_18_753)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.99994 18.0019C4.0306 17.9965 0.00350416 13.9694 -0.00195312 9.00002V8.81998C0.0970061 3.87302 4.17005 -0.0667289 9.11757 -0.00108352C14.0651 0.0645619 18.0322 4.11099 17.9998 9.05884C17.9675 14.0067 13.9479 18.0009 8.99994 18.0019ZM8.98554 16.2015H8.99994C12.9758 16.1976 16.1962 12.9723 16.1943 8.99642C16.1923 5.02054 12.9686 1.79851 8.99274 1.79851C5.01686 1.79851 1.79321 5.02054 1.79122 8.99642C1.78924 12.9723 5.00966 16.1976 8.98554 16.2015ZM9.90013 13.501C9.90013 13.9981 9.4971 14.4012 8.99994 14.4012V14.4012C8.50278 14.4012 8.09975 13.9981 8.09975 13.501V13.501C8.09975 13.0038 8.50278 12.6008 8.99994 12.6008V12.6008C9.4971 12.6008 9.90013 13.0038 9.90013 13.501V13.501ZM9.90013 11.7006H8.09975C8.0713 10.5282 8.6814 9.43283 9.69309 8.83979C10.2872 8.38429 10.8003 7.99181 10.8003 7.19964C10.8003 6.20532 9.99427 5.39926 8.99994 5.39926C8.00562 5.39926 7.19956 6.20532 7.19956 7.19964H5.39919V7.11863C5.41366 5.8322 6.11333 4.65121 7.23465 4.02053C8.35596 3.38985 9.72856 3.40529 10.8354 4.06104C11.9422 4.71679 12.6152 5.91321 12.6007 7.19964C12.5362 8.17096 12.0153 9.05427 11.1964 9.58064C10.4562 10.0453 9.97641 10.8299 9.90013 11.7006Z"
			/>
		</g>
		<defs>
			<clipPath id="clip0_18_753">
				<rect width="18" height="18" />
			</clipPath>
		</defs>
	</svg>
</template>
