<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1419_26167)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893L14.1516 4.73734C14.5421 5.12786 14.5421 5.76103 14.1516 6.15155C13.761 6.54208 13.1279 6.54208 12.7373 6.15155L10 3.41421V11.2222C10 11.7745 9.55229 12.2222 9 12.2222C8.44772 12.2222 8 11.7745 8 11.2222V3.41421L5.26266 6.15155C4.87214 6.54208 4.23897 6.54208 3.84845 6.15155C3.45792 5.76103 3.45792 5.12786 3.84845 4.73734L8.29289 0.292893ZM1 10.6667C1.55228 10.6667 2 11.1144 2 11.6667V15.2222C2 15.6477 2.35228 16 2.77778 16H15.2222C15.4285 16 15.6263 15.9181 15.7722 15.7722C15.9181 15.6263 16 15.4285 16 15.2222V11.6667C16 11.1144 16.4477 10.6667 17 10.6667C17.5523 10.6667 18 11.1144 18 11.6667V15.2222C18 15.9589 17.7073 16.6655 17.1864 17.1864C16.6655 17.7073 15.9589 18 15.2222 18H2.77778C1.24772 18 0 16.7523 0 15.2222V11.6667C0 11.1144 0.447715 10.6667 1 10.6667Z"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1419_26167">
				<rect width="18" height="18" />
			</clipPath>
		</defs>
	</svg>
</template>
