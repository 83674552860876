import { IconSizes } from '../constants/iconSizes'

export const iconSizesConfig: { [k in IconSizes]: string } = {
    [IconSizes.XXS]: 'h-auto min-h-min w-2',
    [IconSizes.XS]: 'h-auto min-h-min w-3',
    [IconSizes.S]: 'h-auto min-h-min w-4',
    [IconSizes.M]: 'h-auto min-h-min w-5',
}

export const getIconSizeConfig = (key: IconSizes): string => {
    return iconSizesConfig[key] as string
}
