<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 4C4 3.44772 4.44772 3 5 3H13C13.5523 3 14 3.44772 14 4V8C14 8.55228 13.5523 9 13 9H5C4.44772 9 4 8.55228 4 8V4ZM6 7H12V5H6V7Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 11C4 10.4477 4.44772 10 5 10C5.55228 10 6 10.4477 6 11C6 11.5523 5.55228 12 5 12C4.44772 12 4 11.5523 4 11Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5 13C5.55228 13 6 13.4477 6 14C6 14.5523 5.55228 15 5 15C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11 10C10.4477 10 10 10.4477 10 11C10 11.5523 10.4477 12 11 12H13C13.5523 12 14 11.5523 14 11C14 10.4477 13.5523 10 13 10H11Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10 14C10 13.4477 10.4477 13 11 13H13C13.5523 13 14 13.4477 14 14C14 14.5523 13.5523 15 13 15H11C10.4477 15 10 14.5523 10 14Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1 3C1 1.34315 2.34315 0 4 0H14C15.6569 0 17 1.34315 17 3V15C17 16.6569 15.6569 18 14 18H4C2.34315 18 1 16.6569 1 15V3ZM4 2C3.44772 2 3 2.44772 3 3V15C3 15.5523 3.44772 16 4 16H14C14.5523 16 15 15.5523 15 15V3C15 2.44772 14.5523 2 14 2H4Z"
		/>
	</svg>
</template>
