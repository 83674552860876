<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L14.4142 9L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L10.5858 10H1C0.447715 10 0 9.55228 0 9C0 8.44772 0.447715 8 1 8H10.5858L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16 18H7C5.89543 18 5 17.1046 5 16V12H7V16H16V2H7V6H5V2C5 0.89543 5.89543 0 7 0H16C17.1046 0 18 0.89543 18 2V16C18 17.1046 17.1046 18 16 18Z"
		/>
	</svg>
</template>
