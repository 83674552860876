<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.2059 3.70588C12.2059 5.75259 10.5467 7.41177 8.50003 7.41177C6.45333 7.41177 4.79415 5.75259 4.79415 3.70588C4.79415 1.65918 6.45333 0 8.50003 0C10.5467 0 12.2059 1.65918 12.2059 3.70588Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.61768 13.2353C1.61768 10.6038 3.6719 8.47059 6.20591 8.47059H10.7941C13.3282 8.47059 15.3824 10.6038 15.3824 13.2353V16.4118C15.3824 17.2889 14.6976 18 13.853 18H3.14709C2.30242 18 1.61768 17.2889 1.61768 16.4118V13.2353Z"
		/>
	</svg>
</template>
