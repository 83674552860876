<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.75241 5.59073L9 7.83831L11.2476 5.59073C11.5684 5.26994 12.0885 5.26994 12.4093 5.59073C12.7301 5.91152 12.7301 6.43162 12.4093 6.7524L10.1617 8.99999L12.4093 11.2476C12.7301 11.5684 12.7301 12.0885 12.4093 12.4093C12.0885 12.73 11.5684 12.73 11.2476 12.4093L9 10.1617L6.75241 12.4093C6.43162 12.73 5.91152 12.73 5.59073 12.4093C5.26994 12.0885 5.26994 11.5684 5.59073 11.2476L7.83832 8.99999L5.59074 6.7524C5.26995 6.43162 5.26995 5.91152 5.59074 5.59073C5.91152 5.26994 6.43162 5.26994 6.75241 5.59073Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM2.39286 9C2.39286 5.35098 5.35098 2.39286 9 2.39286C12.649 2.39286 15.6071 5.35098 15.6071 9C15.6071 12.649 12.649 15.6071 9 15.6071C5.35098 15.6071 2.39286 12.649 2.39286 9Z"
		/>
	</svg>
</template>
