<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.86986 15.3438L4.02272 17L16.1301 2.65617L13.9773 1L1.86986 15.3438Z" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.3455 15.8948L10.3469 15.8961L10.3483 15.8974C11.0471 16.5204 11.9967 16.8136 13.1554 16.8136C14.314 16.8136 15.2637 16.5204 15.9624 15.8974L15.9638 15.8961L15.9653 15.8948C16.6636 15.2566 17 14.3989 17 13.3581V13.2121C17 12.1576 16.6562 11.2971 15.9394 10.6717C15.2292 10.0382 14.2893 9.73572 13.1554 9.73572C11.9955 9.73572 11.0453 10.0367 10.3469 10.6741C9.64573 11.3 9.31073 12.1598 9.31073 13.2121V13.3581C9.31073 14.3989 9.64714 15.2566 10.3455 15.8948ZM13.9016 14.2108L13.8983 14.2142L13.8951 14.2177C13.7361 14.3891 13.5049 14.49 13.1554 14.49C12.8086 14.49 12.5661 14.3904 12.3907 14.2142C12.2229 14.0314 12.1267 13.7834 12.1267 13.4415V13.1079C12.1267 12.7659 12.2229 12.5179 12.3907 12.3352C12.5661 12.159 12.8086 12.0594 13.1554 12.0594C13.5049 12.0594 13.7361 12.1603 13.8951 12.3317L13.8983 12.3352L13.9016 12.3385C14.0825 12.5222 14.184 12.7687 14.184 13.1079V13.4415C14.184 13.7806 14.0825 14.0272 13.9016 14.2108Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2.03474 7.34542L2.03616 7.34672L2.03761 7.34801C2.73635 7.971 3.686 8.26428 4.84464 8.26428C6.00328 8.26428 6.95292 7.971 7.65166 7.34801L7.65311 7.34672L7.65453 7.34542C8.35286 6.70723 8.68927 5.84953 8.68927 4.80873V4.66276C8.68927 3.60829 8.34551 2.74775 7.62869 2.12239C6.91848 1.48881 5.97857 1.18635 4.84464 1.18635C3.68475 1.18635 2.73455 1.4873 2.03616 2.12477C1.33499 2.7507 1 3.61049 1 4.66276V4.80873C1 5.84953 1.33641 6.70723 2.03474 7.34542ZM5.59087 5.66147L5.58755 5.66484L5.58434 5.6683C5.42536 5.83972 5.19414 5.94063 4.84464 5.94063C4.49789 5.94063 4.25537 5.841 4.07997 5.66482C3.91217 5.48208 3.81596 5.23406 3.81596 4.89213V4.5585C3.81596 4.21658 3.91217 3.96856 4.07997 3.78582C4.25537 3.60964 4.49788 3.51 4.84464 3.51C5.19414 3.51 5.42536 3.61092 5.58433 3.78234L5.58755 3.7858L5.59087 3.78917C5.7718 3.97279 5.87331 4.21937 5.87331 4.5585V4.89213C5.87331 5.23127 5.7718 5.47785 5.59087 5.66147Z"
		/>
	</svg>
</template>
