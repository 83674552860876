<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.2325 5.7675C12.4556 5.99066 12.4556 6.35247 12.2325 6.57563L9.80812 8.99999L12.2325 11.4244C12.4556 11.6475 12.4556 12.0093 12.2325 12.2325C12.0093 12.4556 11.6475 12.4556 11.4244 12.2325L9 9.80811L6.57563 12.2325C6.35247 12.4556 5.99067 12.4556 5.76751 12.2325C5.54435 12.0093 5.54435 11.6475 5.76751 11.4244L8.19188 8.99999L5.76751 6.57563C5.54436 6.35247 5.54436 5.99066 5.76751 5.76751C5.99067 5.54435 6.35248 5.54435 6.57564 5.76751L9 8.19187L11.4244 5.76751C11.6475 5.54435 12.0093 5.54435 12.2325 5.7675Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM15.8571 9C15.8571 5.2129 12.7871 2.14286 9 2.14286C5.2129 2.14286 2.14286 5.2129 2.14286 9C2.14286 12.7871 5.2129 15.8571 9 15.8571C12.7871 15.8571 15.8571 12.7871 15.8571 9Z"
		/>
	</svg>
</template>
